import * as $ from 'jquery';
import * as DropZone from 'dropzone';
import '../sass/style.scss';
//const dz = Dropzone;
// require('jquery-ui');
require('jquery-ui/ui/widgets/sortable');
//require('jquery-ui/ui/disable-selection');

const randRange = (min:number, max:number) => Math.floor(Math.random() * (max - min + 1) + min);

function nl2br (str:string, is_xhtml:boolean = false) {     
	let breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br/>' : '<br>';      
	return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1'+ breakTag +'$2');  
}  

let CSRF_TOKEN = document.querySelector('meta[name="csrf-token"]').getAttribute("content");

eval("DropZone.autoDiscover = false");
let myDropzone = new DropZone(".dropzone",{ 
	maxFilesize: .5,  // 1 mb
	acceptedFiles: ".jpeg,.jpg,.png,.gif",
	dictDefaultMessage: $('html').attr('lang') == 'ja' ? 'アップロードする画像をドロップ' : 'Drop files here to upload',
	renameFilename: (filename)=>{
		let newName:any = new Date().getTime() + '_' + filename;
		console.log(newName)
		return newName;
	},
	success: (file:any, response)=>{
		if(file.dataURL)
		$(file.previewElement).attr('data-image', file.dataURL);
	}
});
//myDropzone.autoDiscover = 
myDropzone.on("sending", (file, xhr, formData) => {
		formData.append("_token", CSRF_TOKEN);
}); 

setupLibrary();
attachEventToRemoveButton();

function setupLibrary(){

	let libData = JSON.parse(localStorage.getItem('kl_library'));

	if(libData && libData.length){
		for(let i = 0; i < libData.length; i++){
			let imgSrc = libData[i];
			let $img = $('<img>');
			$img.attr('src', imgSrc);
			$('.library').append('<div class="image"><button class="remove-button"><i class="fas fa-minus-circle"></i></button></div>');
			$('.library .image:last').append($img);
		};

		$( ".library").sortable({
			update: ()=>{
				updateLibrary();
			}
		});	
	}

}

function generateProblems(numberOfProblems:number = 10, leftSideMinNum:number = 1, leftSideMaxNum:number = 10, rightSideMinNumber:number = 1, rightSideMaxNumber:number = 10, leftSideNumbers:any, rightSideNumbers:any, maxSumNumber:number = 100, problemText:string, useImages:number){

	$('.problems').removeClass('text-only').html('');
	
	//let problemStr:any = $.parseHTML(problemText);

	$('.problem-text').html('');
	$('.problem-text').html(problemText);

	let imgAry:any = [];
	if($('.library .image').length){
		$('.library .image img').each((i,elem)=>{
			let imgSrc = $(elem).attr('src');
			//console.log(imgSrc);
			if(imgSrc)
			imgAry.push(imgSrc);
		});
	} else {
		imgAry.push('/assets/img/apple.png');
	}

	let count = 0;
	let problems = [];
	let maxNum = numberOfProblems;
	let maxImgNum = imgAry.length-1;
	let leftSideNumsAry = leftSideNumbers.split(',');
	let rightSideNumsAry = rightSideNumbers.split(',');

	let index = 0;
	
	for(let i = 0; i < 10000; i++){
		let n = leftSideNumbers ? Number(leftSideNumsAry[Math.floor(Math.random() * leftSideNumsAry.length)]) : randRange(leftSideMinNum, leftSideMaxNum);
		let o = rightSideNumbers ? Number(rightSideNumsAry[Math.floor(Math.random() * rightSideNumsAry.length)]) : randRange(rightSideMinNumber, rightSideMaxNumber);
		let str = String(n) + String(o);
		
		if(useImages == 1){
			// if(n + o <= maxSumNumber){
			
			if(n + o <= maxSumNumber && $.inArray(str, problems) == -1){
				
				problems.push(str);
				$('.problems').append('<li><div class="cols"><div class="col"><div class="icons"></div></div><div class="col"><div class="icons"></div></div><div class="col answer"><input type="text" class="frame" data-answer="'+(n+o)+'"></div></div></li>');
				$('.problems li:last .cols .col:eq(0)').prepend('<div class="number">'+n+'</div>');
				$('.problems li:last .cols .col:eq(1)').prepend('<div class="number">'+o+'</div>');
				for(let j = 0; j < n; j++){
					if(j - ((Math.floor(n / 10)) * 10) < 0){
						if(j % 10 == 0){ 
							$('.problems li:last .cols .col:eq(0) .icons').append('<div class="group"></div>');
						}
						$('.problems li:last .cols .col:eq(0) .icons .group:last').append('<div class="icon"><img src="'+imgAry[index]+'"></div>');
					} else {
						$('.problems li:last .cols .col:eq(0) .icons').append('<div class="icon"><img src="'+imgAry[index]+'"></div>');
					}
					// index++;
					// if(index > maxImgNum)
					// index = 0;
				}
				for(let j = 0; j < o; j++){				
					if(j - ((Math.floor(o / 10)) * 10) < 0){	
						if(j % 10 == 0){
							$('.problems li:last .cols .col:eq(1) .icons').append('<div class="group"></div>');
						}
						$('.problems li:last .cols .col:eq(1) .icons .group:last').append('<div class="icon"><img src="'+imgAry[index]+'"></div>');
					} else {
						$('.problems li:last .cols .col:eq(1) .icons').append('<div class="icon"><img src="'+imgAry[index]+'"></div>');
					}
					
				}	
				index++;
				//console.log(index)
				if(index > maxImgNum)
				index = 0;

				count++;
			}
		} else {
			// if(n + o <= maxSumNumber){
			if(n + o <= maxSumNumber && $.inArray(str, problems) == -1){
				let index = 0;
				problems.push(str);
				$('.problems').addClass('text-only').append('<li><span class="number left">'+n+'</span><span class="sign">+</span><span class="number right">'+o+'</span><span class="sign">=</span><span class="answer"><input type="text" class="frame" data-answer="'+(n+o)+'"></span></li>');
				count++;
			}			
		}

		if(count > maxNum-1)
		break;

	}

	$('.answer input').on('blur', (e)=>{
		let val = $(e.currentTarget).val();
		let answer = $(e.currentTarget).attr('data-answer');
		if(val == answer){
			$(e.currentTarget).parent().addClass('correct');
		}
	});



}

$('.generate-worksheet').on('click', (e)=>{
	e.preventDefault();
	let leftSideMaxNum = $('#left-side-min').val() ? Number($('#left-side-min').val()) : 0;
	let leftSideMinNum = $('#left-side-max').val() ? Number($('#left-side-max').val()) : 10;
	let rightSideMaxNum = $('#right-side-min').val() ? Number($('#right-side-min').val()) : 0;
	let rightSideMinNum = $('#right-side-max').val() ? Number($('#right-side-max').val()) : 10;
	let leftSideNums = $('#left-side-numbers').val();
	let rightSideNums = $('#right-side-numbers').val();
	let numberOfProblems = $('#number-of-problems').val() ? Number($('#number-of-problems').val()) : 10;
	let useImages = Number($('#use-images:checked').val());
	let problemText:string = nl2br(String($('#problem-text').val()));
//alert(problemText)
	let maxSumNum = $('#max-sum').val() ? Number($('#max-sum').val()) : 100;
	
	generateProblems(numberOfProblems, leftSideMaxNum, leftSideMinNum, rightSideMaxNum, rightSideMinNum, leftSideNums, rightSideNums, maxSumNum, problemText, useImages);
});


$('.add-to-library').on('click', (e)=>{
	e.preventDefault();

	//if($('.dropzone .dz-complete').length)
	//$('.library').addClass('visible');

	$('.dropzone .dz-complete').each((i,elem)=>{
		let imgSrc = $(elem).attr('data-image');
		let $img = $('<img>');
		$img.attr('src', imgSrc);
		$('.library').append('<div class="image"><button class="remove-button"><i class="fas fa-minus-circle"></i></button></div>');
		$('.library .image:last').append($img);
	});

	//if($(".library").sortable('destroy') != 'undelined')
	//console.log($(".library").sortable('destroy'));

	$(".library").sortable({
		update: ()=>{
			updateLibrary();
		}
	});

	attachEventToRemoveButton();
	updateLibrary();

});

$('.reset-library').on('click', (e)=>{
	e.preventDefault();
	$('.library .image').remove();
	updateLibrary();
});

function attachEventToRemoveButton(){
	$('.remove-button').off('click').on('click', (e)=>{
		e.preventDefault();
		$(e.currentTarget).closest('.image').remove();
		updateLibrary();
	});
}

function updateLibrary(){
	let array:any = [];
	localStorage.removeItem('kl_library');

	if($('.library .image').length){
		$('.library .image img').each((i,elem)=>{
			let imgSrc = $(elem).attr('src');
			if(imgSrc)
			array.push(imgSrc);
		});

		let strJson = JSON.stringify(array);
		localStorage.setItem('kl_library', strJson);	
	}
	
}